AgrimarketServices.service('strikeService', ['$http', 'configurationFactory',
    function ($http, configurationFactory) {

    	var SendRequest = function (configfile, callback) {
    		$http(configfile)
			    .success(function (data) {
			    	callback(data, null);
			    })
			    .error(function (data, status) {
			    	callback(data, status);
			    });
    	};

    	this.RecalculateStrikes = function (idOffer, callback) {
    		var config = {
    			method: 'POST',
    			url: configurationFactory.Url['Api'] + 'Strike/RecalculateStrikesByIdOffer',
				params: { idOffer: idOffer}
    		};
    		SendRequest(config, callback);
    	};

		this.GetStrikesByIdExpiry = function (idExpiry, callback) {
        	var config = {
        		method: 'GET',
        		url: configurationFactory.Url['Api'] + 'Strike/GetStrikesByIdExpiry',
        		params: { idExpiry: idExpiry }
        	};
        	SendRequest(config, callback);
        };
    }]);