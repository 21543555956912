AgrimarketControllers.controller('subscribeContractMultiCropsController', ['$scope', '$rootScope', '$location', 'currentUserFactory', 'enumFactory', 'configurationFactory', 'parametersFactory', 'offerService', 'parametersService', 'contractsService', '$filter', 'conditionsService', 'signatureService', 'contractingDelayService', 'typeOrdersService', 'targetPriceService', 'userService', '$sce',
    function ($scope, $rootScope, $location, currentUserFactory, enumFactory, configurationFactory, parametersFactory, offerService, parametersService, contractsService, $filter, conditionsService, signatureService, contractingDelayService, typeOrdersService, targetPriceService, userService, $sce) {

        var init = function () {
            $scope.selectedVariety = {};
            $scope.firstSendSmsMessageDelayedSigning = false;
            $scope.checkValidCondition = false;
            $scope.showUrlSellConditionAttachment = false;
            $scope.showUrlUtilConditionAttachment = false;

            $scope.enumRoles = enumFactory.Roles;
            $scope.withConditions = true;

            $scope.enumRoles = enumFactory.Roles;
            $scope.effortPriceTcEnabled = false;
            $scope.tcEffortInputIsDelta = parametersFactory.Parameters.TcEffortInputMode.Value == 'DELTA';
            $scope.enumTypeEffortTc = enumFactory.TypeEffortTc;
            $scope.enumFactory = enumFactory;

            $scope.selectedConnection = {
                Connection: {}
            };
            $scope.signatureYouSign = {};
            $scope.showListChooseContact = false;
            $scope.moduleYousignIsActive = $rootScope.ModuleSignatureElectroniqueYouSign.Active;
            $scope.IsUneal = $rootScope.paramNameCoop === enumFactory.Coops.Uneal ? true : false;
            $scope.listConnection = [];
            $scope.showConfirmButton = true;
            $scope.delayedSigning = parametersFactory.Parameters.DelayedSigning.Value === "1";
            $scope.contractValidationDisabled = true;
            
            $scope.signingEnabled = ($scope.moduleYousignIsActive || $rootScope.ModuleElectronicSignature.Active) && $rootScope.activateSignature && (!($rootScope.paramDelayedSigning == '1' && $rootScope.User.IdRole === $scope.enumRoles.TC) || ($rootScope.User.IdRole === $scope.enumRoles.TC && $rootScope.isTcSigningForUser));
            $scope.showCheckedCondition = !($rootScope.paramDelayedSigning == '1' && $rootScope.User.IdRole == $scope.enumRoles.TC) || ($rootScope.User.IdRole === $scope.enumRoles.TC && $rootScope.isTcSigningForUser);
            $scope.showButtonSigningForTc = $rootScope.User.IdRole == $scope.enumRoles.TC && $rootScope.paramDelayedSigning && $rootScope.activateSignature && $rootScope.paramIsTCAuthorizedToSignInModeDelayed && !$rootScope.goToDelayedSigning;

            $scope.user = currentUserFactory.User;
            $scope.mobileNumberToSendSms = $rootScope.selectedClientFromTc ? $rootScope.selectedClientFromTc.MobileNumber : currentUserFactory.Connection ? currentUserFactory.Connection.MobileNumber : $scope.user.MobileNumber;
            $scope.idTypeFixation = $scope.enumFactory.TypeFixations.GlobalPrice.IdTypeFixation;

            $scope.termsAndConditions = {
                isChecked: false
            };
            $scope.security = {
                Code: ""
            };
            $scope.counter = {
                start: false,
                pause: false
            };


            if ($rootScope.User.IdRole === $scope.enumRoles.TC && $rootScope.User.EffortPriceTcEnabled && !$rootScope.isTcSigningForUser) { //Si on est un TC et qu'il y a des efforts de prix possible alors le TC à le droit de saisir un effort
                $scope.effortPriceTcEnabled = true;
            }


            //Prise en compte des modifications des parametres par l'Admin
            parametersService.GetParameters(function (dataParam, statusParam) {
                if (statusParam !== undefined && statusParam !== null) {
                    if (statusParam === 400) {
                        $scope.loginErrorMessage = dataParam[0].errors[0].Message;
                    }
                } else {
                    parametersFactory.Parameters = dataParam;

                    if (!parametersFactory.Parameters.FileTermCondition.Value)
                        $scope.showUrlSellConditionAttachment = false;
                    else {
                        $scope.urlSellConditionAttachment = configurationFactory.Url['Api'] + 'Upload/GetAttachment?p=' + parametersFactory.Parameters.FileTermCondition.Value;
                        $scope.showUrlSellConditionAttachment = true;
                    }

                    if (!parametersFactory.Parameters.FileTermeUse.Value)
                        $scope.showUrlUtilConditionAttachment = false;
                    else {
                        $scope.urlUtilConditionAttachment = configurationFactory.Url['Api'] + 'Upload/GetAttachment?p=' + parametersFactory.Parameters.FileTermeUse.Value;
                        $scope.showUrlUtilConditionAttachment = true;
                    }
                }
            });

            if ($rootScope.goToDelayedSigning && $rootScope.idContractForDelayedSigning) {
                $scope.isGlobalFixation = true;
                $rootScope.goToDelayedSigning = false;
                $rootScope.isTcSigningForUser = false;
                contractsService.GetContractForDelayedSigning($rootScope.idContractForDelayedSigning, function (data, status) {
                    if (status !== null) {
                        if (status === 400) {
                            $scope.errorMessage = data[0].errors[0].Message;
                        }
                    } else {
                        $scope.resumeOffer = data;
                        if (data.LabelVariety)
                            $scope.selectedVariety = {
                                Label: data.LabelVariety
                            };
                        $rootScope.hasSmsCodeSigningAlreadySend = data.HasSmsCodeSigningAlreadySend;
                        goToStep("validation");
                    }
                });
            } else if ($rootScope.idOfferSelected) {
                // Dans le cas ou il ne sagit pas d'un contrat en attente de signature, on passe nos deux params à false et null
                $rootScope.idContractForDelayedSigning = null;
                $rootScope.goToDelayedSigning = false;
                $rootScope.isTcSigningForUser = false;
                $rootScope.hasSmsCodeSigningAlreadySend = false;
                // Récupération de l'offre à souscrire
                offerService.GetOfferByIdAndIdExpiry($rootScope.idOfferSelected, $rootScope.idExpirySelected, $scope.idTypeFixation, function (data, status) {
                    if (status !== null) {
                        if (status === 400)
                            $scope.errorMessage = data[0].errors[0].Message;
                    } else {
                        $scope.resumeOffer = data;
                        $scope.contractValidationDisabled = data.ContractValidationDisabled;
                        $scope.signingEnabled = $scope.signingEnabled && $scope.contractValidationDisabled && $scope.resumeOffer.ContractSigningEnabled ;
                        GetCondParts();

                        if ($scope.quantity) {
                            $scope.resumeOffer.tonnageCurrent = Number($scope.quantity);
                        }
                        if ($scope.effortPriceTcEnabled) {
                            $scope.resumeOffer.EffortPriceTc = $scope.resumeOffer.GlobalPriceWithCoeffConverted;
                            $scope.resumeOffer.DeltaEffortPriceTc = 0;
                        }
                        if (!$scope.step)
                        goToStep("contractualisation");
                    }
                });
            } else
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_IdentifierOfOfferIsMissing.Content;

        };

        var GetCondParts = function () {
            if ($scope.IsUneal) {
                conditionsService.GetBestPath($scope.resumeOffer.IdOffer, function (bestPath) {
                    conditionsService.GetCondParts($scope.resumeOffer.IdOffer, $scope.resumeOffer.IdTree, null, false, bestPath, $rootScope.idExpirySelected, $scope.resumeOffer.IdVariety, function (res) {
                        $scope.errorMessage = res.errorMessage;
                        $scope.conditionParticulars = res.conditionParticulars;
                    });
                });
            }
            else {
                conditionsService.GetCondParts($scope.resumeOffer.IdOffer, $scope.resumeOffer.IdTree, null, false, '', $rootScope.idExpirySelected, $scope.resumeOffer.IdVariety, function (res) {
                    $scope.errorMessage = res.errorMessage;
                    $scope.conditionParticulars = res.conditionParticulars;

                });
            }
        };

        $scope.ChangeVariety = function () {
            GetCondParts();           
        };

        var SaveNewContrat = function (newContractVm) {
            contractsService.SaveNewContract(newContractVm, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isToLate = true;
                        $scope.isConfirmed = false;
                        $scope.counter.pause = true;
                    }
                } else {
                    $scope.idContract = data.idContract;
                    $scope.hashContract = data.hashContract;
                    $scope.counter = {};
                    $scope.counter.start = false;
                    goToStep("confirmation");
                }
            });
        };

        var UpdateContract = function (idContract) {
            contractsService.SignContractForDelayedSigning(idContract, $scope.selectedConnection.Connection.IdConnection, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isToLate = true;
                        $scope.isConfirmed = false;
                        $scope.counter.pause = true;
                    }
                } else {
                    $scope.isConfirmed = false; 
                    $scope.idContract = data.idContract;
                    $scope.hashContract = data.hashContract;
                    $scope.counter = {};
                    $scope.counter.start = false;
                    goToStep("confirmation");
                }
            });
        };

        var CheckIsValidTonnageCurrent = function (tonnageMaxLimit, tonnageMaxCropProduct) {
            offerService.CheckIsValidTonnageCurrent($scope.resumeOffer.tonnageCurrent, $scope.resumeOffer.TonnageMin, $scope.resumeOffer.TonnageMax, $scope.resumeOffer.QuantityLeft, $scope.resumeOffer.Quotity, '', $scope.resumeOffer.idTypeOrder, $scope.resumeOffer.IdTypeCover, tonnageMaxLimit, tonnageMaxCropProduct, function (error) {
                $scope.errorTonnageMessage = error.substring(1, error.length - 1); // Permet de supprimer les guillemets en début et fin de message
                if (tonnageMaxCropProduct || tonnageMaxCropProduct === 0) { //demande ducroquet deuxieme check, on laisse passer //On est obligé d'ajouter || tonnageMaxCropProduct === 0 car sinon dans le cas où il reste 0 alors on ne rentre pas dans le if
                    $scope.resumeOffer.MaxCropProductTonnage = null;
                }
                if ($scope.errorTonnageMessage)
                    return;

                if ($scope.effortPriceTcEnabled) {

                    var coeffValue = "";
                    $scope.resumeOffer.EffortPriceTc = $scope.resumeOffer.EffortPriceTc != undefined? $scope.resumeOffer.EffortPriceTc : $scope.resumeOffer.GlobalPriceWithCoeffConverted;
                    var totalEffort = $scope.resumeOffer.EffortPriceTc + $scope.resumeOffer.DeltaEffortPriceTc;

                    userService.CheckEffortTc(totalEffort, $scope.resumeOffer.GlobalPriceWithCoeffConverted, $scope.enumTypeEffortTc.Price, coeffValue, true, $scope.resumeOffer.DecimalCurrencyUnitForComponents, function (data) {
                        if (data.ErrorMessage) {
                            $scope.errorEffortPriceTcMessage = data.ErrorMessage;
                            return;
                        }

                        $scope.effortPriceTc = data.EffortTc;
                        $scope.marketPriceEffortPriceTc = $scope.resumeOffer.MarketPrice + $scope.effortPriceTc + $scope.totalCondPart;
                        $scope.refPriceEffortPriceTc = $scope.resumeOffer.RefPrice + $scope.effortPriceTc + $scope.totalCondPart;
                        $scope.finalPriceEffortPriceTc = parseFloat($scope.resumeOffer.FinalPrice) + $scope.effortPriceTc + $scope.totalCondPart;//On fait un parsefloat car il considère comme une chaine de caractère et au lieu de faire 16+4 et afficher 20 il affiche 164 si on ne fait pas de parsefloat
                        $scope.finalPriceEffortPriceTcWithCoeffConverted = $filter('roundByDecimalAndConverter')($scope.finalPriceEffortPriceTc, $scope.resumeOffer.DecimalCurrencyUnitFinal, $scope.resumeOffer.ConverterCoeffValue);
                        
                        goToStep("validation");
                    });
                }
                else {                    
                    goToStep("validation");
                }

            });
        };

        $scope.Validate = function () {
            $scope.errorMessage = "";
            $scope.errorTonnageMessage = "";
            $scope.errorPriceMessage = "";
            $scope.errorEffortPriceTcMessage = "";
            $rootScope.lastOfferSelected = $scope.resumeOffer.IdOffer;

            // Vérification des conditions particulières
            $scope.resumeOffer.LstGroupConditionsSelectedValues = [];
            if ($scope.withConditions) {
                $scope.errorMessage = conditionsService.CheckAndSetGroupConditions($scope.conditionParticulars, $scope.resumeOffer.LstGroupConditionsSelectedValues);
                if ($scope.errorMessage)
                    return;
            }
       
            // Vérification de la variété
            if ($scope.resumeOffer.LstVarieties.length > 0 && !$scope.resumeOffer.IdVariety) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseEnterAVariety.Content;
                return;
            }
            else if ($scope.resumeOffer.LstVarieties.length > 0)
                $scope.selectedVariety = $filter('filter')($scope.resumeOffer.LstVarieties, { IdVariety: $scope.resumeOffer.IdVariety }, true)[0];


            // Calcul de la somme des conditions particulières séléctionnées
            $scope.totalLstGroupConditionsSelectedValues = conditionsService.GetTotalLstGroupConditionsSelectedValues($scope.resumeOffer.LstGroupConditionsSelectedValues);

            // Calcul du prix final
            $scope.totalCondPart = !!$scope.totalLstGroupConditionsSelectedValues ? $scope.totalLstGroupConditionsSelectedValues : $scope.resumeOffer.TotalConditionsValue;
            $scope.totalCondPart = !!$scope.totalCondPart ? $scope.totalCondPart : 0;
            var finalPrice = $scope.resumeOffer.GlobalPrice + $scope.totalCondPart;
            $scope.resumeOffer.FinalPriceWithCoeffConverted = $filter('roundByDecimalAndConverter')(finalPrice, $scope.resumeOffer.DecimalCurrencyUnitForComponents, $scope.resumeOffer.ConverterCoeffValue);

            // Vérification du délai de contractualisation
            if (!$rootScope.idContractForDelayedSigning) {
                $scope.errorMessage = contractingDelayService.VerifyContractingDelay($scope.timeToContracting);
                if ($scope.errorMessage) {
                    $scope.isToLate = true;
                    return;
                }
            }

            // Vérification du tonnage du contrat
            CheckIsValidTonnageCurrent($scope.resumeOffer.tonnageCurrent, $scope.resumeOffer.MaxCropProductTonnage);

        };

        $scope.ValidateConnection = function () {
            if (!$scope.selectedConnection.Connection.IdConnection) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.ChooseContacte_OfferSubscribe_ListEmpty.Content;
                return;
            }
            $scope.showConfirmButton = false;
            $scope.isConfirmed = true;
            $scope.errorMessage = "";
            $scope.mobileNumberToSendSms = $scope.selectedConnection.Connection.MobileNumber;

            if ($rootScope.idContractForDelayedSigning){
                $scope.SendSecurityCode();
                $scope.firstSendSmsMessageDelayedSigning = false;
                $scope.counter.pause = false;
                $scope.isConfirmed = false;
                $scope.security.Code = "";
                $scope.security.CodeSendingAway = false;
                $scope.showConfirmButton = true;
                $scope.showListChooseContact = false;
            }
            else {
                // Création de la vm du contrat
                var newContractVm = getContractVm();

                if ($scope.signingEnabled && $rootScope.ModuleElectronicSignature.Active)
                    checkContractAndSendSmsForWithoutThirdParty(newContractVm);
                else if ($scope.signingEnabled && $scope.moduleYousignIsActive)
                    checkContractAndSendSmsForYouSign(newContractVm);
            }
        };

        $scope.Confirm = function () {
            $scope.isConfirmed = true;
            $scope.errorMessage = "";
            $scope.successMessage = "";
            $scope.counter.pause = true;

            if ($rootScope.idConnectionLogged)
                    $scope.selectedConnection.Connection.IdConnection = $rootScope.idConnectionLogged;

            // Vérification de l'acceptation des condition générale de vente
            if (!$scope.termsAndConditions.isChecked && $scope.showCheckedCondition) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseAcceptGeneralConditionsOfSaleBeforeValidation.Content;
                $scope.isConfirmed = false;
                $scope.counter.pause = false;
                return;
            }
            // Vérification du delai de contractualisation
            if (!$rootScope.idContractForDelayedSigning) {
                $scope.errorMessage = contractingDelayService.VerifyContractingDelay($scope.timeToContracting);
                if ($scope.errorMessage) {
                    $scope.isToLate = true;
                    $scope.isConfirmed = false;
                    return;
                }
            }

            if ($rootScope.idContractForDelayedSigning) {
                if (!$rootScope.hasSmsCodeSigningAlreadySend) 
                {
                    // Gestion des contacts
                    if ($scope.signingEnabled && parametersFactory.Parameters.bUseTableConnections.Value === "1" && !$scope.selectedConnection.Connection.IdConnection)
                        getListConnections();
                    else
                        $scope.SendSecurityCode();
                    $scope.firstSendSmsMessageDelayedSigning = false;
                }
                else
                    $scope.firstSendSmsMessageDelayedSigning = true;
                
                $scope.isConfirmed = false;
                goToStep("signatureContract");
            } else {
                // Création de la vm du contrat
                var newContractVm = getContractVm();

                if (!$scope.contractValidationDisabled)
                    return SaveNewContrat(newContractVm);

                if ($scope.step === "validation") {
                    // Gestion des contacts
                    if ($scope.signingEnabled && parametersFactory.Parameters.bUseTableConnections.Value === "1" && !$scope.selectedConnection.Connection.IdConnection)
                        getListConnections();
                    // Envoi signature YOUSIGN
                    else if ($scope.signingEnabled && $scope.moduleYousignIsActive)
                        checkContractAndSendSmsForYouSign(newContractVm);
                    // Envoi signature SANS TIERS DE CONFIANCE
                    else if ($scope.signingEnabled && $rootScope.ModuleElectronicSignature.Active && $scope.showCheckedCondition)
                        checkContractAndSendSmsForWithoutThirdParty(newContractVm);
                    else
                        SaveNewContrat(newContractVm);
                }
                else if ($scope.step === "signatureContract") {
                    // Vérification du code pour signature SANS TIERS DE CONFIANCE et sauvegarde du contrat
                    if ($scope.signingEnabled && $rootScope.ModuleElectronicSignature.Active)
                        checkSmsCodeAndSaveContractForWithoutThirdParty(newContractVm);
                    // Vérification du code pour signature YOUSIGN et mise à jour du contrat
                    else if ($scope.signingEnabled && $scope.moduleYousignIsActive)
                        checkSmsCodeForYouSign();
                }
            }
        };

        $scope.UpdateContractDelayedSigning = function () {
            $scope.isConfirmed = true;
            $scope.successMessage = "";
            signatureService.CheckSecurityCodeFirmOrCommitment($scope.security.Code, $rootScope.idContractForDelayedSigning, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isConfirmed = false;
                    }
                }
                else {
                    $scope.errorMessage = "";
                    UpdateContract($rootScope.idContractForDelayedSigning);
                }
            });
        };

        $scope.ConditionParticularsOnChange = function (condition) {
            conditionsService.ConditionParticularsOnChange($scope.resumeOffer.IdOffer, condition, '', $rootScope.idExpirySelected, $scope.resumeOffer.IdVariety);
        };

        $scope.AutocompletConditionParticularsOnChange = function (selectedValue, condition) {
            condition.selectedValue = selectedValue.originalObject.IdTreeCondition;
            $scope.conditionSelected = condition;
            conditionsService.ConditionParticularsOnChange($scope.resumeOffer.IdOffer, condition, '', $rootScope.idExpirySelected, $scope.resumeOffer.IdVariety);
        };

        $scope.AutocompleteCondPartSearch = function (str, LstOptions) {
            var matches = [];
            LstOptions.forEach(function (option) {
                if ((option.Label.toLowerCase().indexOf(str.toString().toLowerCase()) >= 0)) {
                    matches.push(option);
                }
            });
            return matches;
        };

        $scope.inputChanged = function (str) {
            if ($scope.conditionSelected) {
                $scope.conditionSelected.LstConditionParticularsChilds = [];
            }
        };

        $scope.SetTonnageCurrent = function () {
            $scope.resumeOffer.tonnageCurrent = $scope.resumeOffer.surface * $scope.resumeOffer.rendement;
        };

        $scope.SendSecurityCode = function () {
            $scope.security.Code = "";

            if ($rootScope.ModuleElectronicSignature.Active && $rootScope.activateSignature) {
                var idConnection = $scope.selectedConnection.Connection.IdConnection ? $scope.selectedConnection.Connection.IdConnection : "";
                signatureService.SendSmsForElectronicSignature($scope.timeToContracting, $rootScope.idContractForDelayedSigning, idConnection, function (res) {
                    $scope.isToLate = res.isToLate;
                    $scope.errorMessage = res.errorMessage;
                    if (!$scope.firstSendSmsMessageDelayedSigning)
                        $scope.successMessage = res.successMessage;
                    else
                        $scope.successMessage = "";
                    $scope.firstSendSmsMessageDelayedSigning = false;
                });
            }
            else if ($scope.moduleYousignIsActive && $rootScope.activateSignature) {
                signatureService.ResendCodeYouSign($scope.signatureYouSign.Signature.MemberId, $scope.signatureYouSign.Signature.ProcedureId, function (data, status) {
                    if (status !== null) {
                        if (status === 400) {
                            $scope.errorMessage = data[0].errors[0].Message;
                            $scope.isConfirmed = false;
                        }
                    }
                    else {
                        if (data) {
                            $scope.newAuthenticationId = data.AuthenticationId;
                            $scope.errorMessage = "";
                            $scope.successMessage = $rootScope.editableObjectsFactory.EditableObjects.Success_OfferSubscribe_CodeResend.Content;
                        }
                    }
                });
            }
        };

        $scope.Cancel = function () {
            $location.path("user/offers/view/" + enumFactory.TypeOffer.MultiCampagne);
        };

        $scope.GoToSubscribeDelayedSigning = function () {
          $rootScope.goToDelayedSigning = true;
          $rootScope.idContractForDelayedSigning = $scope.idContract;         
          $rootScope.isTcSigningForUser = true;
          $location.path("/user/subscribe/contractMultiCrops/");
        };

        $rootScope.onRefresh = function () {
            init();
        };

        function goToStep(step) {
            $scope.step = step;
            moveVehicle();
        }

        function getContractVm() {
            return {
                IdTypeContract: enumFactory.TypeContract.MultiCampagne,
                IdTypeOrder: enumFactory.TypeOrders.Vente.IdTypeOrder,
                IdTypeCover: $scope.resumeOffer.IdTypeCover,
                IdOffer: $scope.idOfferSelected,
                IdCrop: $scope.resumeOffer.IdCrop,
                IdProduct: $scope.resumeOffer.IdProduct,
                Quantity: $scope.resumeOffer.tonnageCurrent,
                Price: $scope.resumeOffer.GlobalPrice,
                OfferPrice: $scope.resumeOffer.GlobalPrice,
                IdVariety: $scope.resumeOffer.IdVariety,
                LstTreeUserGroupConditionSelectedVms: $scope.resumeOffer.LstGroupConditionsSelectedValues,
                Surface: $scope.resumeOffer.surface,
                SurfaceYield: $scope.resumeOffer.rendement,
                IdTypeFixation: $scope.idTypeFixation,
                CoefficientChange: $scope.resumeOffer.ConverterCoeffValue, // Envoi du coefficient permettant de convertir le prix à la Devise/Unité des composantes.
                DecimalCurrencyUnitForComponents: $scope.resumeOffer.DecimalCurrencyUnitForComponents, // Envoi de la décimal pour arrondir le prix à la décimal des composantes.
                EffortPriceTc: $scope.effortPriceTcEnabled ? $scope.effortPriceTc : null,
                DisableContractCoverByTc: $scope.resumeOffer.ShowCheckBoxCoverContract ? !$scope.coverContract.isChecked : null,
                PaymentDateString: $rootScope.User.IsSuperTc && $rootScope.paramNameCoop === enumFactory.Coops.Ducroquet ? $scope.resumeOffer.PaymentDateString : null,
                FreeText: $scope.resumeOffer.FreeText,
                IdConnection: $scope.selectedConnection.Connection.IdConnection
            };
        }

        function checkContractAndSendSmsForWithoutThirdParty(newContractVm) {
            signatureService.CheckAndSendSmsForElectronicSignature(newContractVm, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isConfirmed = false;
                    }
                }
                else {
                    $scope.counter.pause = false;
                    $scope.isConfirmed = false;
                    $scope.security.Code = "";
                    $scope.security.CodeSendingAway = false;
                    $scope.showConfirmButton = true;
                    $scope.showListChooseContact = false;
                    goToStep("signatureContract");
                }
            });
        }

        function checkContractAndSendSmsForYouSign(newContractVm) {
            contractsService.SaveNewContractAndSendSmsYouSign(newContractVm, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isToLate = true;
                        $scope.isConfirmed = false;
                        $scope.counter.pause = true;
                    }
                } else {
                    $scope.isConfirmed = true;
                    $scope.hashContract = data.HashContract;   
                    $scope.idContract = data.IdContract;
                    GetPdfContractMultiCrops($scope.idContract, $scope.hashContract);
                    if (data) {
                        if (data.CanAvoidSigning) {
                            $scope.showConfirmButton = true;                                   
                            $scope.counter = {};
                            $scope.counter.start = false;
                            goToStep("confirmation");
                        }
                        else {
                            $scope.showConfirmButton = true;
                            $scope.showListChooseContact = false;
                            $scope.signatureYouSign = data;
                            $scope.isConfirmed = false;
                            goToStep("signatureContract");
                        }
                    }
                }
            });
        }

        function getListConnections() {
            signatureService.GetListConnection($rootScope.selectedClientFromTcForSubscribe.IdUser, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isConfirmed = false;
                    }
                }
                else {
                    $scope.listConnection = data;
                    if ($scope.listConnection.length === 0)
                        $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.ChooseContacte_OfferSubscribe_ListEmpty_NoBinding_Contacte.Content;
                    else if ($scope.listConnection.length === 1)
                    {
                        $scope.selectedConnection.Connection.IdConnection = $scope.listConnection[0].IdConnection;
                        $scope.ValidateConnection();
                        return;
                    }
                    $scope.isConfirmed = false;
                    $scope.showListChooseContact = true;
                    goToStep("signatureContract");
                }
            });
        }

        function checkSmsCodeAndSaveContractForWithoutThirdParty(newContractVm) {
            var idContract = "";
            if ($scope.idContract)
                idContract = $scope.idContract;

            signatureService.CheckSecurityCodeFirmOrCommitment($scope.security.Code, idContract, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isConfirmed = false;
                    }
                }
                else {
                    newContractVm.IsSignedWithoutThirdParty = true;
                    SaveNewContrat(newContractVm);
                }
            });
        }

        function checkSmsCodeForYouSign() {
            $scope.isConfirmed = true;
            $scope.showConfirmButton = false;
            var idConnection = $scope.selectedConnection.Connection.IdConnection ? $scope.selectedConnection.Connection.IdConnection : "";
            signatureService.SignatureWithCodeYouSign($scope.signatureYouSign.Signature.FilesIds[0], $scope.newAuthenticationId ? $scope.newAuthenticationId : $scope.signatureYouSign.Signature.AuthenticationId, $scope.security.Code, idConnection, $scope.signatureYouSign.Signature.MemberId, $scope.signatureYouSign.Signature.ProcedureId, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isConfirmed = false;
                    }
                }
                else {
                    if (data && (data === "true" || data === "1")) {
                        $scope.errorMessage = "";
                        goToStep("confirmation");
                    }
                    else {
                        $scope.successMessage = "";
                        $scope.showConfirmButton = true;
                        $scope.isConfirmed = false;
                        if (!$scope.security.Code)
                            $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseEnterCodeReceivedBySms.Content;
                        else
                            $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_CodeEnteredNotCorrespondToCodeSended.Content;
                    }
                }
            });
        }

        function GetPdfContractMultiCrops (idContract, hashContract) {
            if (idContract && hashContract) {
                $scope.contractMultiCropsUrlPdf = $sce.trustAsResourceUrl($filter('pdfLinkContract')({k: idContract, h: hashContract})) ;
            }
        }

        init();
    }]);