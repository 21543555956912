AgrimarketControllers.controller('offersController', ['$scope', '$rootScope', '$sce', '$location', '$filter', 'typeCommitmentsService', 'offerService', 'cropsService', 'productsService', 'typeQuotesService', 'typeOffersService', 'operationService',  'enumFactory', '$routeParams', 'compagnyService', 'groupContactService', 'parametersFactory', 'dateService', '$timeout', 'strikeService',
    function ($scope, $rootScope, $sce, $location, $filter, typeCommitmentsService, offerService, cropsService, productsService, typeQuotesService, typeOffersService, operationService, enumFactory, $routeParams, compagnyService, groupContactService, parametersFactory, dateService, $timeout, strikeService) {
        $scope.filterCrop = {};
        $scope.filterName = {};
        $scope.filterProduct = {};
        $scope.filterTypeQuote = {};
        $scope.filterTypeOffer = {};
        $scope.filterCompagny = {};
        $scope.filterMarket = {};
        $scope.filterComment = {};
        $scope.filterOperation = {};
        $scope.filterTypeCommitmentForSubscribe = {};
        $scope.showAllExpiryOffer = true;
        $scope.AddOffer = function () { $location.path('/admin/offer/createoffer/-1'); };
        $scope.enumFactory = enumFactory;
        $scope.parambUseIncidenceOffer = parametersFactory.Parameters.bUseIncidenceOffer.Value === "1";
        $scope.parambManageIncidenceBase = parametersFactory.Parameters.bManageIncidenceBase.Value === "1";
        $scope.paramBUseOperation = parametersFactory.Parameters.bUseOperation.Value === "1";
        $scope.IsUneal = $rootScope.paramNameCoop === enumFactory.Coops.Uneal ? true : false;
        $scope.parambUseNotificationPush = parametersFactory.Parameters.bUseNotificationPush.Value === "1";
        $scope.parambUseNotificationSms = parametersFactory.Parameters.bUseNotificationSms.Value === "1";

        $scope.allSelected = false;
        $scope.offerAction = {
            LstIdOffer: [],
            IsDisable: null,
        };
        $scope.currentOfferUserIncidence = {};
        $scope.selectedUserAutoCompleteObject = {};
        $scope.TotalTonnage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Step5_TotalTonnage.Content;
        $scope.TotalSurface = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Step5_TotalSurface.Content;

        $scope.ActionOfferMessage_Add = $rootScope.editableObjectsFactory.EditableObjects.Admin_ListingOffer_ActionOfferMessage_AddMessage.Content;
        $scope.ActionOfferMessage_Edit = $rootScope.editableObjectsFactory.EditableObjects.Admin_ListingOffer_ActionOfferMessage_EditMessage.Content;
        $scope.ActionOfferMessage_Delete = $rootScope.editableObjectsFactory.EditableObjects.Admin_ListingOffer_ActionOfferMessage_DeleteMessage.Content;
        $scope.PopUpOfferMessage_Title = $rootScope.editableObjectsFactory.EditableObjects.Admin_ListingOffer_PopUpOfferMessage_Title.Content;

        $scope.bActiveDecomposeBase = parametersFactory.Parameters.bActiveDecomposeBase.Value === "1";
        $scope.parambActiveOfferGroupContactIncidence = parametersFactory.Parameters.bActiveOfferGroupContactIncidence.Value === "1";

        $scope.ActionOfferOption_RecalculateStrikes = $rootScope.editableObjectsFactory.EditableObjects.Admin_ListingOffer_ActionOfferOption_RecalculateStrikes.Content;
        $scope.ActionOfferOption_ShowStrikes = $rootScope.editableObjectsFactory.EditableObjects.Admin_ListingOffer_ActionOfferOption_ShowStrikes.Content;

        $scope.enumTypeOffer = enumFactory.TypeOffer;
        $scope.enumTypeCalculOption = enumFactory.TypeCalculOption;
        $scope.groupContactsMenuItems = [];
        var idsGroupContactChecked = [];

        var setFilters = function (callBack) {
            if ($rootScope.filtersListingOffers) {
                if ($rootScope.filtersListingOffers.filterCrop)
                    $scope.filterCrop = $rootScope.filtersListingOffers.filterCrop;
                if ($rootScope.filtersListingOffers.filterProduct)
                    $scope.filterProduct = $rootScope.filtersListingOffers.filterProduct;
                if ($rootScope.filtersListingOffers.filterTypeQuote)
                    $scope.filterTypeQuote = $rootScope.filtersListingOffers.filterTypeQuote;
                if ($rootScope.filtersListingOffers.filterTypeOffer)
                    $scope.filterTypeOffer = $rootScope.filtersListingOffers.filterTypeOffer;
                if ($rootScope.filtersListingOffers.filterOperation)
                    $scope.filterOperation = $rootScope.filtersListingOffers.filterOperation;
                if ($rootScope.filtersListingOffers.filterCompagny)
                    $scope.filterCompagny = $rootScope.filtersListingOffers.filterCompagny;
                if ($rootScope.filtersListingOffers.idStatus)
                    $scope.idStatus = $rootScope.filtersListingOffers.idStatus;
                if ($rootScope.filtersListingOffers.filterMarket)
                    $scope.filterMarket = $rootScope.filtersListingOffers.filterMarket;
                if ($rootScope.filtersListingOffers.filterTypeCommitmentForSubscribe)
                    $scope.filterTypeCommitmentForSubscribe = $rootScope.filtersListingOffers.filterTypeCommitmentForSubscribe;
            }
            callBack();
        };

        var getAllOffersCallback = function (data, status) {
            if (status !== null) {
                if (status === 400)
                    $scope.errorMessage = data[0].errors[0].Message;
            } else {
                $scope.allOffers = data;
                $scope.lstMarketWsd = [];
                $scope.offerGroupContactIncidenceVm = {};
                data.forEach(function (o) {
                    if (o.IdMarketWSD && $scope.lstMarketWsd.indexOf({ IdMarketWSD: o.IdMarketWSD, LabelMarket: o.LabelMarket }) === -1)
                        $scope.lstMarketWsd.push({ IdMarketWSD: o.IdMarketWSD, LabelMarket: o.LabelMarket });

                    if (o.ListGroupContactVm && o.ListGroupContactVm.length > 0) {

                        var grContactShow = 0;
                        var showed = 5;
                        var lenghtGroupContact = o.ListGroupContactVm.length;
                        var tooltipTextGrContact = "";
                        var tooltipDataGrContact = "";

                        var groupContactsString = '<ul>';
                        o.ListGroupContactVm.forEach(function (itemGrp) {

                            if (grContactShow < showed) {
                                groupContactsString += '<li>' + itemGrp.Label + "</li>";
                            }
                            grContactShow++;

                            if (grContactShow === showed) {
                                groupContactsString += '</ul>';

                                if (lenghtGroupContact - showed > 0) {
                                    tooltipTextGrContact += "+" + lenghtGroupContact - showed + " autres régions";
                                }
                            }

                            if (grContactShow > showed) {
                                tooltipDataGrContact += itemGrp.Label + "<br>";
                            }

                        });
                        groupContactsString += '</ul>';
                        o.GroupContactsString = $sce.trustAsHtml(groupContactsString);
                        o.tooltipDataGrContact = $sce.trustAsHtml(tooltipDataGrContact);
                        o.tooltipTextGrContact = $sce.trustAsHtml(tooltipTextGrContact);
                    }

                    if (o.ListVarieryOfferVm && o.ListVarieryOfferVm.length > 0) {
                        o.VarietiesString = '<ul>';
                        o.ListVarieryOfferVm.forEach(function (itemVariety) {
                            o.VarietiesString += '<li>' + itemVariety.Label + "</li>";
                        });
                        o.VarietiesString += '</ul>';
                    }

                    if (o.ListOfferGroupContactTonnageVm && o.ListOfferGroupContactTonnageVm.length > 0 && o.IsTonnageLimit) {
                        var offerGrQuantityShow = 0;
                        var sumQuantity = 0;
                        var showedQuantity = 5;
                        var lenghtOfferQuantity = o.ListOfferGroupContactTonnageVm.length;
                        var tooltipTextQuantity = "";
                        var tooltipDataQuantity = "";

                        o.ListOfferGroupContactTonnageVm.forEach(function (item) {
                            sumQuantity += item.QuantityLimit;
                        });

                        var offerGroupContactQuantitystring = '<ul>';
                        var typeQuantityText = o.IdTypeOffer === $scope.enumTypeOffer.EngagementSurface ? $scope.TotalSurface : $scope.TotalTonnage;
                        offerGroupContactQuantitystring += '<li>' + typeQuantityText + " " + sumQuantity + "</li>";
                        o.ListOfferGroupContactTonnageVm.forEach(function (itemOfferGroupContactQuantity) {
                            if (offerGrQuantityShow < showedQuantity) {
                                offerGroupContactQuantitystring += '<li>' + itemOfferGroupContactQuantity.Label + " : " + itemOfferGroupContactQuantity.QuantityLimit + "</li>";
                            }
                            offerGrQuantityShow++;

                            if (offerGrQuantityShow === showedQuantity) {
                                offerGroupContactQuantitystring += '</ul>';

                                if (lenghtOfferQuantity - showedQuantity > 0) {
                                    tooltipTextQuantity += "+" + lenghtOfferQuantity - showedQuantity + " autres régions";
                                }
                            }

                            if (offerGrQuantityShow > showedQuantity) {
                                tooltipDataQuantity += itemOfferGroupContactQuantity.Label + " : " + itemOfferGroupContactQuantity.QuantityLimit + "<br>";
                            }
                        });
                        o.OfferGroupContactQuantitystring = $sce.trustAsHtml(offerGroupContactQuantitystring);
                        o.tooltipDataQuantity = $sce.trustAsHtml(tooltipDataQuantity);
                        o.tooltipTextQuantity = $sce.trustAsHtml(tooltipTextQuantity);
                    } else if (o.QuantityLeft) {
                        o.quantityLeft = o.QuantityLeft;
                    }

                    if (o.QuotesExpiryOfferManagerVM && o.QuotesExpiryOfferManagerVM.length > 0) {
                        o.QuotesExpiryOfferManagerVM.forEach(function (item) {
                            item.PaymentDate = $filter('date')(item.PaymentDate, 'dd/MM/yyyy');
                        });
                    }
                    o.PaymentDate = $filter('date')(o.PaymentDate, 'dd/MM/yyyy');

                    // Gestion des incidences par couple Offre / GroupeContact
                    if ($scope.parambActiveOfferGroupContactIncidence && o.ListOfferGroupContactPricesVm && o.ListOfferGroupContactPricesVm.length > 0) {
                        o.ListOfferGroupContactPricesVm.forEach(function (itemGrp) {
                            $scope.offerGroupContactIncidenceVm[itemGrp.IdOffer + '_' + itemGrp.IdGroupContact] = itemGrp.Incidence;
                        });
                    }
                });
            }
            selectIdsGroupContact();
            setFilters($scope.ChangeElementSelected);
            $scope.loading = false;
        };


        if ($routeParams.idStatus !== undefined) {
            $scope.idStatus = $routeParams.idStatus;
        } else
            $scope.idStatus = enumFactory.OfferStatus.Enabled;

        $scope.GetAllOffers = function () {
            $scope.loading = true;
            if ($rootScope.filtersListingOffers) {
                var idCrop = $rootScope.filtersListingOffers && $rootScope.filtersListingOffers.filterCrop && $rootScope.filtersListingOffers.filterCrop.IdCrop ? $rootScope.filtersListingOffers.filterCrop.IdCrop : undefined;
                var idProduct = $rootScope.filtersListingOffers && $rootScope.filtersListingOffers.filterProduct && $rootScope.filtersListingOffers.filterProduct.IdProduct ? $rootScope.filtersListingOffers.filterProduct.IdProduct : undefined;
                offerService.GetAllOffers($scope.idStatus, idCrop, idProduct, getAllOffersCallback);
            }
            else {
                offerService.GetDefaultFilters(function (data) {

                    var idCrop = data.IdCrop ? data.IdCrop : undefined;
                    var idProduct = data.IdProduct ? data.IdProduct : undefined;

                    if (idCrop) {
                        $rootScope.idCropSelected = data.IdCrop;
                        $scope.filterCrop.IdCrop = data.IdCrop;
                    }

                    if (idProduct) {
                        $rootScope.idProductSelected = data.IdProduct;
                        $scope.filterProduct.IdProduct = data.IdProduct;
                    }

                    offerService.GetAllOffers($scope.idStatus, idCrop, idProduct, getAllOffersCallback);
                });
            }
        };

        cropsService.GetCropsInOffers(function (data, status) {
            if (status !== null) {
                if (status === 400)
                    $scope.errorMessage = data[0].errors[0].Message;
            } else {
                $scope.filterCrop.IdCrop = $rootScope.idCropSelected;
                $scope.crops = angular.copy(data);
            }
        });

        productsService.GetProductsInOffers(function (data, status) {
            if (status !== null) {
                if (status === 400)
                    $scope.errorMessage = data[0].errors[0].Message;
            } else {
                $scope.filterProduct.IdProduct = $rootScope.idProductSelected;
                $scope.products = angular.copy(data);
            }
        });

        typeQuotesService.GetTypeQuotesInOffers(function (data, status) {
            if (status !== null) {
                if (status === 400)
                    $scope.errorMessage = data[0].errors[0].Message;
            } else
                $scope.typeQuotes = angular.copy(data);
        });

        operationService.GetOperationsInOffers(function (data,status) {
            if (status !== null) {
                if (status !== 400)
                    $scope.errorMessage = data[0].errors[0].Message;
            } else
                $scope.Operations = angular.copy(data); 
        });

        typeOffersService.GetTypeOffers(function (data, status) {
            if (status !== null) {
                if (status === 400)
                    $scope.errorMessage = data[0].errors[0].Message;
            } else
                $scope.typeOffers = angular.copy(data);
        });

        groupContactService.GetGroupContactsInOffers(function (data, status) {
            if (status !== null) {
                if (status === 400)
                    $scope.errorMessage = data[0].errors[0].Message;
            } else
                $scope.lstGroupContact = angular.copy(data);

            angular.copy($scope.lstGroupContact).forEach(function (item) {
                $scope.groupContactsMenuItems.push({ groupContact: item.Label, idGroupContact: item.IdGroupContact, checked: true });
            });
        });

        $scope.filterByClickGroupContactsMenuItem = function (idGroupContact, checked) {
            $scope.groupContactsMenuItems.forEach(function (groupContact) {
                if (idGroupContact === -1) groupContact.checked = !checked; // Si tous
                else {
                    if (groupContact.idGroupContact === -1) groupContact.checked = false;
                    if (idGroupContact == groupContact.idGroupContact) groupContact.checked = !checked;
                }
            });

            selectIdsGroupContact();
            setGroupContactMultiSelectValues();
            setFilters($scope.ChangeElementSelected);
        };

        var selectIdsGroupContact = function () {
            idsGroupContactChecked = [];
            $scope.groupContactsMenuItems.forEach(function (groupContact) {
                if (groupContact.checked && groupContact.idGroupContact !== -1) idsGroupContactChecked.push(groupContact.idGroupContact);
            });
        };

        var setGroupContactMultiSelectValues = function () {
            $scope.groupContactLabel = "Sélectionnez un groupe contact";
            var groupContactAll = $filter('filter')($scope.groupContactsMenuItems, { idGroupContact: -1 }, true)[0];
            if (groupContactAll.checked) $scope.groupContactLabel = groupContactAll.groupContact;
            else {
                $scope.groupContactsMenuItems.forEach(function (groupContactsMenuItem) {
                    if (groupContactsMenuItem.checked) {
                        if ($scope.groupContactLabel === "Sélectionnez un groupe contact") {
                            $scope.groupContactLabel = groupContactsMenuItem.groupContact;
                        } else {
                            $scope.groupContactLabel += ", " + groupContactsMenuItem.groupContact;
                        }
                    }
                });
            }
        };

        typeCommitmentsService.GetTypeCommitments(function (data, status) {
            if (status === null) {
                $scope.lstTypeCommitments = data;
            }
            else if (status === 400)
                $scope.errorMessage = data[0].errors[0].Message;
            else {
                $scope.errorMessage = status;
            }
        });

        if ($rootScope.ModuleMultipleCompagnies.Active) {
            compagnyService.GetAll(function (data, status) {
                if (status === null)
                    $scope.lstCompagnies = data;
                else if (status === 400)
                    $scope.errorMessage = data[0].errors[0].Message;
                else {
                    $scope.errorMessage = status;
                }
            });
        }
        
        $scope.ChangeElementSelected = function (reloadOffers) {
            $scope.offerAction.LstIdOffer = [];
            $scope.offers = [];
            $scope.allSelected = false;

            var filtreGeneral = $scope.allOffers;
            $rootScope.idCropSelected = $scope.filterCrop.IdCrop;
            $rootScope.idProductSelected = $scope.filterProduct.IdProduct;

            if (!$rootScope.filtersListingOffers) {
                $rootScope.filtersListingOffers = {};
            }

            if ($scope.filterCrop.IdCrop) {
                filtreGeneral = $filter('filter')(filtreGeneral, { IdCrop: parseInt($scope.filterCrop.IdCrop) }, true);
                $rootScope.filtersListingOffers.filterCrop = $scope.filterCrop;
            }
            if ($scope.filterProduct.IdProduct !== undefined && $scope.filterProduct.IdProduct !== null) {
                filtreGeneral = $filter('filter')(filtreGeneral, { IdProduct: parseInt($scope.filterProduct.IdProduct) }, true);
                $rootScope.filtersListingOffers.filterProduct = $scope.filterProduct;
            }
            if ($scope.filterTypeQuote.IdTypeQuote !== undefined && $scope.filterTypeQuote.IdTypeQuote !== null) {
                filtreGeneral = $filter('filter')(filtreGeneral, { IdTypeQuote: parseInt($scope.filterTypeQuote.IdTypeQuote) }, true);
                $rootScope.filtersListingOffers.filterTypeQuote = $scope.filterTypeQuote;
            }
            if ($scope.filterTypeOffer.IdTypeOffer !== undefined && $scope.filterTypeOffer.IdTypeOffer !== null) {
                filtreGeneral = $filter('filter')(filtreGeneral, { IdTypeOffer: parseInt($scope.filterTypeOffer.IdTypeOffer) }, true);
                $rootScope.filtersListingOffers.filterTypeOffer = $scope.filterTypeOffer;
            }
            if ($scope.filterOperation.IdOperation !== undefined && $scope.filterOperation.IdOperation !== null) {
                filtreGeneral = $filter('filter')(filtreGeneral, { IdOperation: parseInt($scope.filterOperation.IdOperation) }, true);
                $rootScope.filtersListingOffers.filterOperation = $scope.filterOperation;
            }
            if ($scope.filterCompagny.IdCompagny !== undefined && $scope.filterCompagny.IdCompagny !== null) {
                filtreGeneral = $filter('filter')(filtreGeneral, { IdCompagny: parseInt($scope.filterCompagny.IdCompagny) }, true);
                $rootScope.filtersListingOffers.filterCompagny = $scope.filterCompagny;
            }
            if ($scope.idStatus !== undefined && $scope.idStatus !== null) {
                filtreGeneral = $filter('filter')(filtreGeneral, { IdStatus: $scope.idStatus }, true);
                $rootScope.filtersListingOffers.idStatus = $scope.idStatus;
            }
            if ($scope.filterMarket.IdMarketWSD) {
                filtreGeneral = $filter('filter')(filtreGeneral, { IdMarketWSD: parseInt($scope.filterMarket.IdMarketWSD) }, true);
                $rootScope.filtersListingOffers.filterMarket = $scope.filterMarket;
            }
            if ($scope.filterTypeCommitmentForSubscribe.IdTypeCommitmentForSubscribe) {
                filtreGeneral = $filter('filter')(filtreGeneral, { IdTypeCommitmentForSubscribe: parseInt($scope.filterTypeCommitmentForSubscribe.IdTypeCommitmentForSubscribe) }, true);
                $rootScope.filtersListingOffers.filterTypeCommitmentForSubscribe = $scope.filterTypeCommitmentForSubscribe;
            }
            if ($scope.filterName.Label) {
                filtreGeneral = $filter('filter')(filtreGeneral, { Label: $scope.filterName.Label });
                $rootScope.filtersListingOffers.filterName = $scope.filterName;
            }

            if ($scope.filterComment.Comment) {
                filtreGeneral = $filter('filter')(filtreGeneral, { Comment: $scope.filterComment.Comment });
                $rootScope.filtersListingOffers.filterComment = $scope.filterComment;
            }

            if ($scope.idStatus == enumFactory.OfferStatus.Enabled) {
                $scope.buttonDesactivateOfferName = "Désactiver des offres";
                $scope.titlePopUpDesactivateOffer = "Désactiver des offres";
                $scope.textPopUpDesactivateOffer = "Êtes-vous sûr de vouloir désactiver ces offres ?";
            }
            else if ($scope.idStatus == enumFactory.OfferStatus.Disabled) {
                $scope.buttonDesactivateOfferName = "Réactiver des offres";
                $scope.titlePopUpDesactivateOffer = "Réactiver des offres";
                $scope.textPopUpDesactivateOffer = "Êtes-vous sûr de vouloir réactiver ces offres ?";
            }

            var lstOffer = []; // Filtre des offres en fonction des GroupContacts

            if (idsGroupContactChecked !== undefined && idsGroupContactChecked.length > 0) {
                filtreGeneral.forEach(function (item) {
                    idsGroupContactChecked.forEach(function (idGroupContact) {
                        if ($filter('filter')(lstOffer, { IdOffer: item.IdOffer }, true)[0] === undefined &&
                            (($rootScope.paramIsTerrenaGroup && ((item.ListOfferGroupContactPricesVm && $filter('filter')(item.ListOfferGroupContactPricesVm, { IdGroupContact: idGroupContact }, true)[0] !== undefined || $filter('filter')($scope.groupContactsMenuItems, { idGroupContact: -1 }, true)[0].checked) && $filter('filter')(lstOffer, { IdOffer: item.IdOffer }, true)[0] === undefined)) || ((item.ListGroupContactVm && $filter('filter')(item.ListGroupContactVm, { IdGroupContact: idGroupContact }, true)[0] !== undefined || item.ListGroupContactVm.length === 0 && $filter('filter')($scope.groupContactsMenuItems, { idGroupContact: -1 }, true)[0].checked) && $filter('filter')(lstOffer, { IdOffer: item.IdOffer }, true)[0] === undefined)))
                            lstOffer.push(item);
                    });
                });
                filtreGeneral = lstOffer;
            }
            if (idsGroupContactChecked !== undefined && idsGroupContactChecked.length === 0) { filtreGeneral = []; }

            $scope.offers = filtreGeneral;

            if (reloadOffers === true) $scope.GetAllOffers();

        };

        $scope.DeleteOffer = function (params) {// a voir pour meilleur point de vue
            offerService.DeleteOffer(params.IdOffer, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        if (angular.isNumber(parseInt(data[0].errors[0].Message))) {
                            $scope.showPopUpMessage();
                        } else {
                            $scope.errorMessage = data[0].errors[0].Message;
                        }
                    }
                } else {
                    $scope.allOffers.splice(params.index, 1);
                    $scope.GetAllOffers();
                }
            });
        };

        $scope.PopUpDeleteOffer = function (deletepopup, index, idOffre) {
            var param = {};
            param.index = index;
            param.IdOffer = idOffre;
            deletepopup(param);
        };

        $scope.PopUpDeleteOfferMessage = function (popUpDeleteOfferMessage, idOfferMessage) {
            $scope.PopUpOfferMessage_Text = $rootScope.editableObjectsFactory.EditableObjects.Admin_ListingOffer_PopUpOfferMessage_Text.Content;
            popUpDeleteOfferMessage(idOfferMessage);
        };

        $scope.DeleteOfferMessage = function (idOfferMessage) {
            offerService.DeleteOfferMessage(idOfferMessage, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        if (angular.isNumber(parseInt(data[0].errors[0].Message))) {
                            $scope.showPopUpMessage();
                        } else {
                            $scope.errorMessage = data[0].errors[0].Message;
                        }
                    }
                } else {
                    $scope.cancelOfferMessage();
                    $scope.GetAllOffers();
                }
            });
        };

        $scope.PopUpAddOfferMessage = function (showPopUpAddOfferMessage, offer) {
            $scope.popUpOfferMessage_OfferMessage = null;
            $scope.errorPopupOfferMessageHtml = "";

            $scope.popUpAddOfferMessage_Title = $rootScope.editableObjectsFactory.EditableObjects.Admin_ListingOffer_PopUpAddOfferMessage_Title.Content;
            $scope.popUpAddOfferMessage_TypeNotification = $rootScope.editableObjectsFactory.EditableObjects.Admin_ListingOffer_PopUpAddOfferMessage_TypeNotification.Content;
            $scope.popUpAddOfferMessage_SendingProgramming = $rootScope.editableObjectsFactory.EditableObjects.Admin_ListingOffer_PopUpAddOfferMessage_SendingProgramming.Content;
            $scope.popUpAddOfferMessage_Instantly = $rootScope.editableObjectsFactory.EditableObjects.Admin_ListingOffer_PopUpAddOfferMessage_Instantly.Content;
            $scope.popUpAddOfferMessage_CustomDateAndHour = $rootScope.editableObjectsFactory.EditableObjects.Admin_ListingOffer_PopUpAddOfferMessage_CustomDateAndHour.Content;
            $scope.popUpAddOfferMessage_CustomDateAndHour_Date = $rootScope.editableObjectsFactory.EditableObjects.Admin_ListingOffer_PopUpAddOfferMessage_CustomDateAndHour_Date.Content;
            $scope.popUpAddOfferMessage_CustomDateAndHour_Hour = $rootScope.editableObjectsFactory.EditableObjects.Admin_ListingOffer_PopUpAddOfferMessage_CustomDateAndHour_Hour.Content;
            $scope.popUpAddOfferMessage_TitleMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_ListingOffer_PopUpAddOfferMessage_TitleMessage.Content;
            $scope.popUpAddOfferMessage_TextMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_ListingOffer_PopUpAddOfferMessage_TextMessage.Content;
            $scope.popUpAddOfferMessage_TextTagsEnabledForMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_ListingOffer_PopUpAddOfferMessage_TextTagsEnabledForMessage.Content;
            $scope.popUpOfferMessage_ListTypeNotificationVm = offer.ListTypeNotificationVm;

            offerService.GetOfferMessageVm(offer.IdOffer, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                } else {
                    $scope.popUpOfferMessage_OfferMessage = data;
                    $scope.popUpOfferMessage_OfferMessage.IdOfferMessage = offer.IdOfferMessage;
                    $scope.popUpOfferMessage_OfferMessage.bSendingImmediate = 0;

                    if ($scope.popUpOfferMessage_ListTypeNotificationVm && $scope.popUpOfferMessage_ListTypeNotificationVm.length === 1)
                        $scope.popUpOfferMessage_OfferMessage.IdTypeNotification = $scope.popUpOfferMessage_ListTypeNotificationVm[0].IdTypeNotification;

                    if (!data.IdOfferMessage) {
                        $scope.popUpOfferMessage_OfferMessage.bSendingImmediate = null;
                        $scope.popUpOfferMessage_OfferMessage.Content = $rootScope.editableObjectsFactory.EditableObjects.Admin_OfferMessage_DefaultText.Content;
                    }
                }
            });
            showPopUpAddOfferMessage();
        };

        $scope.SaveOfferMessage = function () {
            offerService.PostOfferMessageVm($scope.popUpOfferMessage_OfferMessage, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        var errorHtml = data[0].errors[0].Message;
                        $scope.errorPopupOfferMessageHtml = $sce.trustAsHtml(errorHtml);
                        $scope.showPopUpAddOfferMessage();
                    }
                } else {
                    $scope.GetAllOffers();
                }
            });
        };

        /************ Gestion des incidences utilisateur liée à l'offre **********/

        $scope.PopUpAddOfferUserIncidence = function (showPopUpAddOfferUserIncidence, id) {

            getAllOfferUserIncidence(id);
            $scope.popUpOfferMessage_OfferMessage = null;
            $scope.errorPopupOfferMessageHtml = "";

            $scope.popUpAddOfferUserIncidence_Title = $rootScope.editableObjectsFactory.EditableObjects.Admin_ListingOffer_PopUpAddOfferUserIncidence_Title.Content;
            $scope.popUpAddOfferUserIncidence_AddUserButton = $rootScope.editableObjectsFactory.EditableObjects.Admin_ListingOffer_PopUpAddOfferUserIncidence_AddUserButton.Content;
            $scope.popUpAddOfferUserIncidence_TitleUsersColumn = $rootScope.editableObjectsFactory.EditableObjects.Admin_ListingOffer_PopUpAddOfferUserIncidence_TitleUsersColumn.Content;
            $scope.popUpAddOfferUserIncidence_TitleValuesColumn = $rootScope.editableObjectsFactory.EditableObjects.Admin_ListingOffer_PopUpAddOfferUserIncidence_TitleValuesColumn.Content;
            $scope.popUpAddOfferUserIncidence_PlaceholderIncidenceInput = $rootScope.editableObjectsFactory.EditableObjects.Admin_ListingOffer_PopUpAddOfferUserIncidence_PlaceholderIncidenceInput.Content;
            $scope.PopUpAddOfferUserIncidence_PlaceholderUserAutoComplet = $rootScope.editableObjectsFactory.EditableObjects.Admin_ListingOffer_PopUpAddOfferUserIncidence_PlaceholderUserAutoComplet.Content;

            $rootScope.resetInput = 1;
            showPopUpAddOfferUserIncidence();
        };

        $scope.PopUpDeleteOfferUserIncidence = function (popUpDeleteOfferUserIncidence, idOfferUserIncidence) {
            $scope.PopUpDeleteOfferUserIncidence_Title = $rootScope.editableObjectsFactory.EditableObjects.Admin_ListingOffer_PopUpDeleteOfferUserIncidence_Title.Content;
            $scope.PopUpDeleteOfferUserIncidence_Message = $rootScope.editableObjectsFactory.EditableObjects.Admin_ListingOffer_PopUpDeleteOfferUserIncidence_Message.Content;
            popUpDeleteOfferUserIncidence(idOfferUserIncidence);
        };

        $scope.SetSelectedUser = function (user) {
            $rootScope.resetInput = 3;
            $scope.currentOfferUserIncidence.IdUser= user.IdUser;
        };

        $scope.SaveOfferUserIncidence = function () {
            offerService.SaveOfferUserIncidence($scope.currentOfferUserIncidence, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorOfferUserIncidenceMessage = data[0].errors[0].Message;
                    }
                } else {
                    $rootScope.resetInput = 1;
                    getAllOfferUserIncidence();
                }
            });
        };

        $scope.UpdateOfferUserIncidence = function (offerUserIncidence) {

            $scope.currentOfferUserIncidence.IdOfferUserIncidence = offerUserIncidence.IdOfferUserIncidence;
            $scope.currentOfferUserIncidence.IdUser = offerUserIncidence.IdUser;
            $scope.currentOfferUserIncidence.Incidence = offerUserIncidence.Incidence;

            $scope.offerUserIncidenceUpdate = offerUserIncidence.IdOfferUserIncidence;
        };

        $scope.DeleteOfferUserIncidence = function (id) {
            offerService.DeleteOfferUserIncidence(id, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorOfferUserIncidenceMessage = data[0].errors[0].Message;
                    }
                } else {
                    getAllOfferUserIncidence();
                }
            });
        };

        function getAllOfferUserIncidence(id) {
            $scope.errorOfferUserIncidenceMessage = null;
            var idOffer = id != null ? id : $scope.currentOfferUserIncidence.IdOffer;
            offerService.GetDataOfferUserIncidence(idOffer, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                } else {
                    $scope.currentOfferUserIncidence = {};
                    $scope.currentOfferUserIncidence.IdOffer = idOffer;
                    $scope.offerUserIncidenceUpdate = null;

                    $scope.listOfferUserIncidence = data.LstOfferUserIncidence;
                    $scope.listUserOfferGroupContact = data.LstUserOfferGroupContact;
                }
            });
        }

        /************ FIN Gestion des incidences utilisateur liée à l'offre **********/

        $scope.EditFinaliser = function (id) {
            $location.path('/admin/offer/createoffer/' + id);
        };

        $scope.Dupliquer = function (id) {
            $location.path('/admin/offer/dupliquer/' + id);
        };

        $scope.SaveVariablesForOffers = function () {
            $scope.errorMessage = "";
            $scope.successMessage = "";
            $scope.IncidenceBaseErrorMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_EditIncidenceBase_NotANumberErrorMessage.Content;
            $scope.PrimeErrorMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_EditPrime_NotANumberErrorMessage.Content;
            $scope.MarginErrorMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_EditMargin_NotANumberErrorMessage.Content;
            $scope.FinancialCostsErrorMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_EditFinancialCosts_NotANumberErrorMessage.Content;
            $scope.LogisticCostsErrorMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_EditLogisticCosts_NotANumberErrorMessage.Content;
            $scope.DepotErrorMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_EditDepot_NotANumberErrorMessage.Content;
            $scope.OthersErrorMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_EditOthers_NotANumberErrorMessage.Content;
            $scope.BaseErrorMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_EditBase_NotANumberErrorMessage.Content;
            $scope.EditVariableForBaseSuccessMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_EditVariableForBase_SuccessMessage.Content;
            $scope.DatePaymentForBaseErrorMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_EditDatePayment_NotADateErrorMessage.Content;

            var result = [];
            var lstInfosForOffer = [];
            var regexDate = /^(\d{2}\/){2}\d{4}$/;
            $scope.offers.forEach(function (offer) {

                lstInfosForOffer.push({
                    IdOffer: offer.IdOffer,
                    Order: offer.Order
                });

                if ((offer.IdTypeOffer === enumFactory.TypeOffer.Engagement || offer.IdTypeOffer === enumFactory.TypeOffer.EngagementSurface) && offer.PaymentDate && $scope.paramNameCoop == enumFactory.Coops.Ducroquet) {
                    result.push({
                        IdOffer: offer.IdOffer,
                        PaymentDate: offer.PaymentDate != null ? dateService.ConvertDateToDateAPI(offer.PaymentDate) : null
                    });
                }
                else {
                    if (offer.IdTypeOffer === enumFactory.TypeOffer.Engagement || offer.IdTypeOffer === enumFactory.TypeOffer.EngagementSurface || !offer.IdFirstExpiry)
                        return;
                    if (offer.ExpiryBase && isNaN(offer.ExpiryBase)) {
                        $scope.errorMessage = $scope.BaseErrorMessage;
                        return;
                    }
                    if (offer.IncidenceBase && isNaN(offer.IncidenceBase)) {
                        $scope.errorMessage = $scope.IncidenceBaseErrorMessage;
                        return;
                    }
                    if (offer.Prime && isNaN(offer.Prime)) {
                        $scope.errorMessage = $scope.PrimeErrorMessage;
                        return;
                    }
                    if (offer.Margin && isNaN(offer.Margin)) {
                        $scope.errorMessage = $scope.MarginErrorMessage;
                        return;
                    }
                    if (offer.FinancialCosts && isNaN(offer.FinancialCosts)) {
                        $scope.errorMessage = $scope.FinancialCostsErrorMessage;
                        return;
                    }
                    if (offer.LogisticCosts && isNaN(offer.LogisticCosts)) {
                        $scope.errorMessage = $scope.LogisticCostsErrorMessage;
                        return;
                    }
                    if (offer.Depot && isNaN(offer.Depot)) {
                        $scope.errorMessage = $scope.DepotErrorMessage;
                        return;
                    }
                    if (offer.Others && isNaN(offer.Others)) {
                        $scope.errorMessage = $scope.OthersErrorMessage;
                        return;
                    }
                    if (offer.PaymentDate && !regexDate.test(offer.PaymentDate)) {
                        $scope.errorMessage = $scope.DatePaymentForBaseErrorMessage;
                        return;
                    }

                    if ($scope.bActiveDecomposeBase)
                        offer.ExpiryBase = Number(offer.Prime) + Number(offer.Margin) + Number(offer.FinancialCosts) + Number(offer.LogisticCosts) + Number(offer.Depot) + Number(offer.Others);

                    result.push({
                        IdExpiry: offer.IdFirstExpiry,
                        Base: offer.ExpiryBase ? Number(offer.ExpiryBase) : 0,
                        IncidenceBase: offer.IncidenceBase ? Number(offer.IncidenceBase) : null,
                        Prime: offer.Prime ? Number(offer.Prime) : null,
                        Margin: offer.Margin ? Number(offer.Margin) : null,
                        FinancialCosts: offer.FinancialCosts ? Number(offer.FinancialCosts) : null,
                        LogisticCosts: offer.LogisticCosts ? Number(offer.LogisticCosts) : null,
                        Depot: offer.Depot ? Number(offer.Depot) : null,
                        Others: offer.Others ? Number(offer.Others) : null,
                        PaymentDate: offer.PaymentDate != null ? dateService.ConvertDateToDateAPI(offer.PaymentDate) : null
                    });

                    offer.QuotesExpiryOfferManagerVM.forEach(function (exp) {
                        if (exp.Base && isNaN(exp.Base)) {
                            $scope.errorMessage = $scope.BaseErrorMessage;
                            return;
                        }
                        if (exp.IncidenceBase && isNaN(exp.IncidenceBase)) {
                            $scope.errorMessage = $scope.IncidenceBaseErrorMessage;
                            return;
                        }
                        if (exp.Prime && isNaN(exp.Prime)) {
                            $scope.errorMessage = $scope.PrimeErrorMessage;
                            return;
                        }
                        if (exp.Margin && isNaN(exp.Margin)) {
                            $scope.errorMessage = $scope.MarginErrorMessage;
                            return;
                        }
                        if (exp.FinancialCosts && isNaN(exp.FinancialCosts)) {
                            $scope.errorMessage = $scope.FinancialCostsErrorMessage;
                            return;
                        }
                        if (exp.LogisticCosts && isNaN(exp.LogisticCosts)) {
                            $scope.errorMessage = $scope.LogisticCostsErrorMessage;
                            return;
                        }
                        if (exp.Depot && isNaN(exp.Depot)) {
                            $scope.errorMessage = $scope.DepotErrorMessage;
                            return;
                        }
                        if (exp.Others && isNaN(exp.Others)) {
                            $scope.errorMessage = $scope.OthersErrorMessage;
                            return;
                        }
                        if (exp.PaymentDate && !regexDate.test(exp.PaymentDate)) {
                            $scope.errorMessage = $scope.DatePaymentForBaseErrorMessage;
                            return;
                        }

                        if ($scope.bActiveDecomposeBase)
                            exp.Base = Number(exp.Prime) + Number(exp.Margin) + Number(exp.FinancialCosts) + Number(exp.LogisticCosts) + Number(exp.Depot) + Number(exp.Others);

                        result.push({
                            IdExpiry: exp.IdExpiry,
                            Base: exp.Base ? Number(exp.Base) : 0,
                            IncidenceBase: exp.IncidenceBase ? Number(exp.IncidenceBase) : null,
                            Prime: exp.Prime ? Number(exp.Prime) : null,
                            Margin: exp.Margin ? Number(exp.Margin) : null,
                            FinancialCosts: exp.FinancialCosts ? Number(exp.FinancialCosts) : null,
                            LogisticCosts: exp.LogisticCosts ? Number(exp.LogisticCosts) : null,
                            Depot: exp.Depot ? Number(exp.Depot) : null,
                            Others: exp.Others ? Number(exp.Others) : null,
                            PaymentDate: exp.PaymentDate != null ? dateService.ConvertDateToDateAPI(exp.PaymentDate) : null,
                        });
                    });
                }
            });

            var lstOfferGroupContactIncidenceVm = [];
            if ($scope.parambActiveOfferGroupContactIncidence) {
                for (var propertyKey in $scope.offerGroupContactIncidenceVm) {
                    lstOfferGroupContactIncidenceVm.push({ 'IdOffer': propertyKey.split('_')[0], 'IdGroupContact': propertyKey.split('_')[1], Incidence: $scope.offerGroupContactIncidenceVm[propertyKey] });
                }
            }

            if (!$scope.errorMessage) {
                var postData = {
                    offerVariablesInfos: result,
                    offerGroupContactIncidencesInfos: lstOfferGroupContactIncidenceVm,
                    editInfosForOffer: lstInfosForOffer
                };
                $scope.loading = true;
                offerService.SaveVariablesForOffers(postData, function (data, status) {
                    if (status !== null) {
                        if (status === 400)
                            $scope.errorMessage = $sce.trustAsHtml(data[0].errors[0].Message);
                            $scope.loading = false;
                    } else {
                        $scope.GetAllOffers();
                        $scope.successMessage = $scope.EditVariableForBaseSuccessMessage;
                    }
                });
            }
        };

        $rootScope.onRefresh = function () {
            $scope.GetAllOffers();
        };

        $scope.ChangeTab = function (id) {
            $scope.idStatus = id;
            $rootScope.filtersListingOffers.idStatus = id;
            $scope.GetAllOffers();
            //$scope.ChangeElementSelected();
        };

        $scope.SelectAll = function () {
            $scope.offerAction.LstIdOffer = [];

            if ($scope.allSelected) {
                $scope.offers.forEach(function (offerItem) {
                    if (offerItem.CanDisableOrEnableOffer) {
                        $scope.offerAction.LstIdOffer.push(offerItem.IdOffer);
                    }
                });
            }
        };

        $scope.SelectOffer = function (idOffer) {
            var index = $scope.offerAction.LstIdOffer.indexOf(idOffer);
            if (index >= 0) {
                $scope.offerAction.LstIdOffer.splice(index, 1);
            } else {
                $scope.offerAction.LstIdOffer.push(idOffer);
            }
        };

        $scope.PopUpDesactivateOffer = function (showPopUpDesactivateOffer) {
            if ($scope.offerAction.LstIdOffer.length > 0) {
                showPopUpDesactivateOffer();
            }
        };

        $scope.DesactivateOffer = function () {
            $scope.offerAction.IsDisable = $scope.idStatus == enumFactory.OfferStatus.Enabled;
            offerService.DisableOrEnableOffers($scope.offerAction, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                    } else {
                        $location.path('/');
                    }
                } else {
                    $scope.GetAllOffers();
                }
            });
        };

        var init = function () {
            initGroupContacts();
        };

        var initGroupContacts = function () { // intialisation des GroupContacts
            $scope.groupContactLabel = 'Tous';
            $scope.groupContactLabelsMenuItems = [];
            $scope.groupContactsMenuItems.push({ groupContact: 'Tous', idGroupContact: -1, checked: true });
        };

        //Permet de gerer les arrondis des prices pour les decimale > 2
        $scope.$watch("offers", function (n, o) {
            if (n !== o) {
                var regexfloat = /^[0-9]+.[0-9]{3,100}/;
                n.forEach(function (item) {
                    if (regexfloat.test(item.Price)) {
                        item.Price = parseFloat(item.Price).toFixed(2);
                    }
                });
            }
        }, true);

        $scope.GetAllOffers();

        init();

        $scope.toggleSublistAndRecalculHeight = function (offer) {
            offer.showAllExpiryOffer = !offer.showAllExpiryOffer;

            var tableLeft = $("#idForReCalculHeight").find('.t_resp_left'),
                tableScrollable = $("#idForReCalculHeight").find('.t_resp_center'),
                tableRight = $("#idForReCalculHeight").find('.t_resp_right'),
                currentHeaderHeight = $('#idForReCalculHeight').find('.t_resp_center thead').outerHeight();

            $timeout(function () {
                tableScrollable.find('tr.sublist').each(function (i) {
                    tableLeft.find('tr.sublist').eq(i).css({
                        'height': $(this)[0].getBoundingClientRect().height
                    });
                    tableRight.find('tr.sublist').eq(i).css({
                        'height': $(this)[0].getBoundingClientRect().height
                    });

                    i++;
                });
            }, 0);
        };

        $scope.RecalculateStrikes = function (idOffer) {
            $scope.errorMessage = "";
            $scope.successMessage = "";
            strikeService.RecalculateStrikes(idOffer, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                } else {
                    $scope.GetAllOffers();
                    $scope.successMessage = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_RecalculateStrikes_SuccessMessage.Content;
                }
            });
        };

        $scope.GetStrikesByIdExpiry = function () {
            strikeService.GetStrikesByIdExpiry($scope.offerOptionExpirySelected, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorPopupDetailStrikesMessage = data[0].errors[0].Message;
                } else {
                    $scope.expiryStrikeDetail = data;
                }
            });
        };

        $scope.PopUpShowDetailStrikes = function (showPopUpDetailStrikes, idOffer) {
            $scope.errorPopupDetailStrikesMessage = "";
            
            var offer = $filter('filter')($scope.allOffers, { IdOffer: idOffer }, true)[0];
            $scope.offerOptionExpiriesVm = [
                { IdExpiry: offer.IdFirstExpiry, LabelExpiry: offer.LabelExpiry }
            ];

            offer.QuotesExpiryOfferManagerVM.forEach(function (exp) {
                $scope.offerOptionExpiriesVm.push({ IdExpiry: exp.IdExpiry, LabelExpiry: exp.LabelExpiry });
            });

            $scope.offerOptionExpirySelected = $scope.offerOptionExpiriesVm[0].IdExpiry;
            $scope.GetStrikesByIdExpiry($scope.offerOptionExpirySelected);
            $scope.TextExpiryLabel = $rootScope.editableObjectsFactory.EditableObjects.Common_Expiry_Label.Content;
            $scope.HeaderExerciceDate = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Strike_ExerciceDate.Content;
            $scope.HeaderPrime = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Strike_Prime.Content;
            $scope.HeaderSubscriptionCost = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Strike_SubscriptionCost.Content;
            $scope.HeaderStrike = $rootScope.editableObjectsFactory.EditableObjects.Admin_Offer_Strike_Strike.Content;
            showPopUpDetailStrikes();
        };
    }]);
