AgrimarketControllers.controller('commitmentsController', [
    '$scope', '$rootScope', '$routeParams', '$http', '$location', 'contractsService', '$filter', 'colonnesTypeCommitments', 'colonnesContractTypeCommitmentAndProduct', 'colonnesContractProduct', 'colonnesContractTypeCommitment',
    'enumFactory', 'colonnesListFixationsByIdCommitment',
    function ($scope, $rootScope, $routeParams, $http, $location, contractsService, $filter, colonnesTypeCommitments, colonnesContractTypeCommitmentAndProduct, colonnesContractProduct, colonnesContractTypeCommitment, enumFactory, colonnesListFixationsByIdCommitment) {

        if ($rootScope.ModuleEngagementALaMasse.Active) {
            $location.path('/user/commitmentsAll');
        }
        // IFRAME
    }
]);