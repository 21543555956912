AgrimarketControllers.controller('subscribeContractOptionController', ['$scope', '$rootScope', '$location', 'enumFactory', 'configurationFactory', 'currentUserFactory', 'offerService', 'contractsOptionService', 'parametersService', 'contractsService', '$filter', 'conditionsService', 'signatureService', 'typeOrdersService', 'targetPriceService', 'colonnesStrikeOption', 'parametersFactory', '$sce',
    function ($scope, $rootScope, $location, enumFactory, configurationFactory, currentUserFactory, offerService, contractsOptionService, parametersService, contractsService, $filter, conditionsService, signatureService, typeOrdersService, targetPriceService, colonnesStrikeOption, parametersFactory, $sce) {

        var init = function () {
            goToStep("Strike");

            // Tableau strike d'option
            $scope.tableStrikeOptionDataVisible = colonnesStrikeOption.data.DataVisible.split(',');
            $scope.tableStrikeOptionLibelle = colonnesStrikeOption.data.ColumnName.split(',');
            $scope.data = { reverse: true, sortKey: "IdStrike", pageNo: 1, maxItems: 10 };

            $scope.idOfferOption = $rootScope.offerSelected.IdOfferSelected;
            $scope.errorMessage = "";
            $scope.currentContract = {};
            $scope.currentContract.tonnageCurrent = 0;
            $scope.currentContract.isCGChecked = false;
            $scope.showUrlUtilConditionAttachment = false;
            $scope.showUrlSellConditionAttachment = false;
            $scope.selectedConnection = {
                Connection: {}
            };
            $scope.signatureYouSign = {};
            $scope.showListChooseContact = false;
            $scope.moduleYousignIsActive = $rootScope.ModuleSignatureElectroniqueYouSign.Active;
            $scope.mobileNumberToSendSms = $rootScope.selectedClientFromTc ? $rootScope.selectedClientFromTc.MobileNumber : currentUserFactory.Connection ? currentUserFactory.Connection.MobileNumber : currentUserFactory.User.MobileNumber;
            $scope.signingEnabled = ($scope.moduleYousignIsActive || $rootScope.ModuleElectronicSignature.Active) && $rootScope.activateSignature;
            $scope.listConnection = [];
            $scope.showConfirmButton = true;
            $scope.termsAndConditions = {
                isChecked: false
            };

            $scope.security = {
                Code: ""
            };
            $scope.firstSendSmsMessageDelayedSigning = false;


            $scope.idContractOption = 0;

            parametersService.GetParameters(function (dataParam, statusParam) {
                if (statusParam !== undefined && statusParam !== null) {
                    if (statusParam === 400) {
                        $scope.loginErrorMessage = dataParam[0].errors[0].Message;
                    }
                } else {
                    $scope.Parameters = dataParam;

                    if (!$scope.Parameters.FileTermCondition.Value)
                        $scope.showUrlSellConditionAttachment = false;
                    else {
                        $scope.urlSellConditionAttachment = configurationFactory.Url['Api'] + 'Upload/GetAttachment?p=' + $scope.Parameters.FileTermCondition.Value;
                        $scope.showUrlSellConditionAttachment = true;
                    }

                    if (!$scope.Parameters.FileTermeUse.Value)
                        $scope.showUrlUtilConditionAttachment = false;
                    else {
                        $scope.urlUtilConditionAttachment = configurationFactory.Url['Api'] + 'Upload/GetAttachment?p=' + $scope.Parameters.FileTermeUse.Value;
                        $scope.showUrlUtilConditionAttachment = true;
                    }
                }
            });

            $scope.LoadDataForSubscription();

        };

        $scope.Sort = function (sortKey, reverse) {
            $scope.data.sortKey = sortKey;
            $scope.data.reverse = reverse;
            $scope.LoadDataForSubscription();
        };

        $scope.GetData = function (pageNo) {
            $scope.data.pageNo = pageNo;
            $scope.LoadDataForSubscription();
        };

        $scope.SelectStrike = function (idStrike) {
            $scope.strikes.forEach(function (item) {
                if (item.IdStrike == idStrike) {
                    item.IsChecked = true;
                } else {
                    item.IsChecked = false;
                }
            });
        };

        $scope.LoadDataForSubscription = function () {
            $scope.loading = true;
            contractsOptionService.GetDataForSubscribeOption($rootScope.offerSelected.IdExpirySelected, $scope.data.maxItems, $scope.data.pageNo, $scope.data.sortKey, $scope.data.reverse, function (data, status) {
                if (status !== null) {
                    if (status === 400)
                        $scope.errorMessage = data[0].errors[0].Message;
                } else {
                    $scope.Offer = data.Offer;
                    $scope.signingEnabled = $scope.signingEnabled && $scope.Offer.ContractSigningEnabled;
                    $scope.total = data.Total;
                    $scope.strikes = data.Strikes;
                    $rootScope.marketPriceForStrike = data.Offer.Quote;
                    $scope.loading = false;
                }
            });
        };

        $scope.GoToContractualisation = function () {
            $scope.errorStrikeMessage = "";
            var strike = $filter('filter')($scope.strikes, { IsChecked: true }, true)[0];
            if (strike) {
                goToStep("Contractualisation");
                $scope.errorContractualisationMessage = "";
                $scope.selectedStrike = strike;
            } else {
                $scope.errorStrikeMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_StrikeOption_NotSelected.Content;
            }
        };

        $scope.GoToValidation = function () {
            if ($scope.CheckContractualisation())
                goToStep("Validation");
        };

        $scope.ConfirmValidation = function () {
            $scope.showConfirmButton = false;
            $scope.errorSignatureMessage = "";
            $scope.isConfirmed = true;
            $scope.errorMessage = "";
            $scope.successMessage = "";

            if ($rootScope.idConnectionLogged)
                $scope.selectedConnection.Connection.IdConnection = $rootScope.idConnectionLogged;

            // Vérification de l'acceptation des condition générale de vente
            if (!$scope.termsAndConditions.isChecked) {
                $scope.errorValidationMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseAcceptGeneralConditionsOfSaleBeforeValidation.Content;
                $scope.isConfirmed = false;
                return;
            }

            // Création de la vm du contrat
            var newContractOptionVm = getContractOptionVm();

            if ($scope.step === "Validation") {
                //Gestion des contacts
                if ($scope.signingEnabled && parametersFactory.Parameters.bUseTableConnections.Value === "1" && !$scope.selectedConnection.Connection.IdConnection)
                     getListConnections();
                // Envoi signature YOUSIGN
                else if ($scope.signingEnabled && $scope.moduleYousignIsActive)
                    checkContractAndSendSmsForYouSign(newContractOptionVm);
                // // Envoi signature SANS TIERS DE CONFIANCE
                else if ($scope.signingEnabled && $rootScope.ModuleElectronicSignature.Active) {
                    $scope.firstSendSmsMessageDelayedSigning = true;
                    $scope.SendSecurityCode();
                }
                else
                    SaveNewContratOption(newContractOptionVm);
            }
            else if ($scope.step === "SignatureSms") {
                // Vérification du code pour signature SANS TIERS DE CONFIANCE et sauvegarde du contrat
                if ($scope.signingEnabled && $rootScope.ModuleElectronicSignature.Active)
                    checkSmsCodeAndSaveContractForWithoutThirdParty(newContractOptionVm);
                // Vérification du code pour signature YOUSIGN et mise à jour du contrat
                else if ($scope.signingEnabled && $scope.moduleYousignIsActive)
                    checkSmsCodeForYouSign();
            }
        };

        $scope.CheckContractualisation = function () {

            if ($scope.currentContract.tonnageCurrent === undefined || $scope.currentContract.tonnageCurrent === null || $scope.currentContract.tonnageCurrent === 0) {
                $scope.errorContractualisationMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseEnterTonnage.Content;
                return false;
            }

            if ($scope.currentContract.tonnageCurrent <= 0) {
                $scope.errorContractualisationMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseEnterPositiveNumber.Content;
                return false;
            }

            if ($scope.Offer.CommitmentMin !== null && $scope.currentContract.tonnageCurrent < $scope.Offer.CommitmentMin) {
                $scope.errorContractualisationMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseEnterTonnageSuperirorOrEqual.Content + $scope.Offer.CommitmentMin + $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_Tonne.Content;
                return false;
            }

            if ($scope.Offer.CommitmentMax !== null && $scope.currentContract.tonnageCurrent > $scope.Offer.CommitmentMax) {
                $scope.errorContractualisationMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseEnterTonnageInferiorOrEqual.Content + $scope.Offer.CommitmentMax + $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_Tonne.Content;
                return false;
            }

            if ($scope.Offer.QuantityLeft !== null && $scope.currentContract.tonnageCurrent > $scope.Offer.QuantityLeft) {
                $scope.errorContractualisationMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_TonnageIsLimitedOnThisOffer.Content + $scope.Offer.QuantityLeft + $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_Tonne.Content;
                return false;
            }

            if ($scope.Offer.Quotity !== null && $scope.currentContract.tonnageCurrent % $scope.Offer.Quotity !== 0) {
                $scope.errorContractualisationMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseEnterTonnageRespectingQuotity.Content + $scope.Offer.Quotity + ".";
                return false;
            }

            if ($scope.Offer.SubscriptionCapacity && $scope.Offer.SubscriptionCapacity < $scope.currentContract.tonnageCurrent) {
                $scope.errorContractualisationMessage = $rootScope.editableObjectsFactory.EditableObjects.Contract_Option_OfferOptionCapacity_Error.Content;
                return false;
            }

            return true;
        };

        $scope.ValidateConnection = function () {
            if (!$scope.selectedConnection.Connection.IdConnection) {
                $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.ChooseContacte_OfferSubscribe_ListEmpty.Content;
                return;
            }
            $scope.showConfirmButton = false;
            $scope.isConfirmed = true;
            $scope.errorMessage = "";
            $scope.mobileNumberToSendSms = $scope.selectedConnection.Connection.MobileNumber;

            // Création de la vm du contrat
            var newContractOptionVm = getContractOptionVm();

            if ($scope.signingEnabled && $rootScope.ModuleElectronicSignature.Active) {
                $scope.firstSendSmsMessageDelayedSigning = true;
                $scope.SendSecurityCode();
            }
            else if ($scope.signingEnabled && $scope.moduleYousignIsActive)
                checkContractAndSendSmsForYouSign(newContractOptionVm);
        };

        $scope.SendSecurityCode = function () {
            $scope.security.Code = "";
            $scope.errorSignatureMessage = "";

            if ($rootScope.ModuleElectronicSignature.Active && $rootScope.activateSignature) {
                var idConnection = $scope.selectedConnection.Connection && $scope.selectedConnection.Connection.IdConnection ? $scope.selectedConnection.Connection.IdConnection : "";
                signatureService.SendSmsElectronicSignatureForOption(idConnection, function (data, status) {
                    if (status !== null) {
                        if (status === 400) {
                            $scope.errorMessage = data[0].errors[0].Message;
                            $scope.isConfirmed = false;
                        }
                    }
                    else {
                        $scope.security.Code = "";
                        $scope.isConfirmed = false;
                        $scope.showConfirmButton = true;
                        $scope.showListChooseContact = false;

                        if (!$scope.firstSendSmsMessageDelayedSigning)
                            $scope.successMessage = $rootScope.editableObjectsFactory.EditableObjects.Success_OfferSubscribe_CodeResend.Content;
                        else
                            $scope.successMessage = "";

                        $scope.firstSendSmsMessageDelayedSigning = false;
                        goToStep("SignatureSms");
                    }
                });
            }
            else if ($scope.moduleYousignIsActive && $rootScope.activateSignature) {
                signatureService.ResendCodeYouSign($scope.signatureYouSign.Signature.MemberId, $scope.signatureYouSign.Signature.ProcedureId, function (data, status) {
                    if (status !== null) {
                        if (status === 400) {
                            $scope.errorMessage = data[0].errors[0].Message;
                            $scope.isConfirmed = false;
                        }
                    }
                    else {
                        if (data) {
                            $scope.newAuthenticationId = data.AuthenticationId;
                            $scope.errorMessage = "";
                            $scope.successMessage = $rootScope.editableObjectsFactory.EditableObjects.Success_OfferSubscribe_CodeResend.Content;
                            $scope.isConfirmed = false;
                        }
                    }
                });
            }
        };

        var SaveNewContratOption = function (contractOptionVm) {
            $scope.isConfirmed = true;
            contractsOptionService.SaveNewContractOption(contractOptionVm, function (data, status) {
                $scope.errorSignatureMessage = "";
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isConfirmed = false;
                    }
                } else {
                    $scope.idContractOption = data.idContractOption;
                    $scope.hashContractOption = data.hashContractOption;
                    $scope.isConfirmed = false;
                    goToStep("Confirmation");
                }
            });
        };

        $scope.Cancel = function () {
            $location.path("user/offers/view/" + enumFactory.TypeOffer.Option);
        };

        $rootScope.onRefresh = function () {
            init();
        };

        function goToStep(step) {
            $scope.step = step;
            moveVehicle();
        }

        function getContractOptionVm() {
            return {
                IdOffer: $scope.idOfferOption,
                IdExpiry: $rootScope.offerSelected.IdExpirySelected,
                IdStrike: $scope.selectedStrike.IdStrike,
                Quantity: $scope.currentContract.tonnageCurrent,
                Prime: $scope.selectedStrike.Prime,
                Cost: $scope.selectedStrike.Cost,
                Variation: $scope.selectedStrike.Variation,
                StrikeValue: $scope.selectedStrike.Strike,
                DateLimitExercice: $scope.selectedStrike.ExerciceDate,
                IdCrop: $rootScope.offerSelected.IdCropSelected,
                IdTypeOption: $rootScope.offerSelected.IdTypeOptionSelected,
                IdProduct: $rootScope.offerSelected.IdProductSelected,
                IdConnection: $scope.selectedConnection.Connection.IdConnection
            };
        }

        function checkContractAndSendSmsForYouSign(newContractOptionVm) {
            contractsOptionService.SaveNewContractOptionAndSendSmsYouSign(newContractOptionVm, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isToLate = true;
                        $scope.isConfirmed = false;
                        $scope.counter.pause = true;
                    }
                } else {
                    $scope.isConfirmed = true;
                    $scope.idContractOption = data.IdContractOption;
                    $scope.hashContractOption = data.HashContractOption;
                    GetPdfContractOption($scope.idContractOption, $scope.hashContractOption);
                    if (data) {
                        if (data.CanAvoidSigning) {
                            $scope.showConfirmButton = true;
                            $scope.isConfirmed = false;                                   
                            goToStep("Confirmation");
                        }
                        else {
                            $scope.showConfirmButton = true;
                            $scope.showListChooseContact = false;
                            $scope.signatureYouSign = data;
                            $scope.isConfirmed = false;
                            goToStep("SignatureSms");
                        }
                    }
                }
            });
        }

        function getListConnections() {
            signatureService.GetListConnection($rootScope.selectedClientFromTcForSubscribe.IdUser, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorMessage = data[0].errors[0].Message;
                        $scope.isConfirmed = false;
                    }
                }
                else {
                    $scope.listConnection = data;
                    if ($scope.listConnection.length === 0)
                        $scope.errorMessage = $rootScope.editableObjectsFactory.EditableObjects.ChooseContacte_OfferSubscribe_ListEmpty_NoBinding_Contacte.Content;
                    else if ($scope.listConnection.length === 1)
                    {
                        $scope.selectedConnection.Connection.IdConnection = $scope.listConnection[0].IdConnection;
                        $scope.ValidateConnection();
                        return;
                    }
                    $scope.isConfirmed = false;
                    $scope.showListChooseContact = true;
                    $scope.showConfirmButton = true;
                    goToStep("SignatureSms");
                }
            });
        }

        function checkSmsCodeAndSaveContractForWithoutThirdParty(newContractOptionVm) {
            var idContractOption = "";
            if ($scope.idContractOption)
                idContractOption = $scope.idContractOption;
            signatureService.CheckSecurityCodeOption($scope.security.Code, idContractOption, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorSignatureMessage = data[0].errors[0].Message;
                        $scope.isConfirmed = false;
                    }
                }
                else {
                    SaveNewContratOption(newContractOptionVm);
                }
            });
        }

        function checkSmsCodeForYouSign() {
            $scope.isConfirmed = true;
            $scope.showConfirmButton = false;
            signatureService.SignatureWithCodeYouSign($scope.signatureYouSign.Signature.FilesIds[0], $scope.newAuthenticationId ? $scope.newAuthenticationId : $scope.signatureYouSign.Signature.AuthenticationId, $scope.security.Code, null, $scope.signatureYouSign.Signature.MemberId, $scope.signatureYouSign.Signature.ProcedureId, function (data, status) {
                if (status !== null) {
                    if (status === 400) {
                        $scope.errorSignatureMessage = data[0].errors[0].Message;
                        $scope.isConfirmed = false;
                    }
                }
                else {
                    if (data && (data === "true" || data === "1")) {
                        $scope.errorSignatureMessage = "";
                        $scope.isConfirmed = false;
                        goToStep("Confirmation");
                    }
                    else {
                        $scope.successMessage = "";
                        $scope.showConfirmButton = true;
                        $scope.isConfirmed = false;
                        if (!$scope.security.Code)
                            $scope.errorSignatureMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_PleaseEnterCodeReceivedBySms.Content;
                        else
                            $scope.errorSignatureMessage = $rootScope.editableObjectsFactory.EditableObjects.Error_OfferSubscribe_CodeEnteredNotCorrespondToCodeSended.Content;
                    }
                }
            });
        }

        function GetPdfContractOption (idContractOption, hashContractOption) {
            if (idContractOption && hashContractOption) {
                $scope.contractOptionUrlPdf = $sce.trustAsResourceUrl($filter('pdfLinkContractOption')({k: idContractOption, h: hashContractOption})) ;
            }
        }
        
        init();
    }]);
